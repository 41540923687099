.cylinder-background {
  /* position: relative; */
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(232, 205, 237, 0.5) 100%
  );
  height: 100%;
}

.cylinder-background::before {
  content: "";
  /* position: absolute; */
  top: -100%;
  left: 50%;
  width: 200%;
  height: 200%;
  background-color: #fff;
  border-radius: 50%;
  transform: translateX(-50%);
}

.imageContainer {
  transition: transform 0.3s ease !important;
}

.imageContainer:hover {
  border-color: purple !important;
  transform: scale(1.2) !important;
}
