.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  /* Add styles for your spinner animation */
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  /* border-top: 4px solid #3498db; */
  /* #769863 */
  border-top: 4px solid #769863;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
