.label {
  font-size: 25px !important;
  font-weight: 400 !important;
}

.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}
.colored-toast.swal2-icon-error {
  background-color: #f27474 !important;
}
