.sl_IconButton:hover {
  background-color: transparent;
}

.slteam_card {
  transition: 0.3;
  cursor: pointer;
}

.slteam_card:hover {
  transform: scale(1.05);
}
