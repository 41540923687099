.project_h4 {
  font-weight: 700 !important;
  font-size: 50px !important;
  text-align: center !important;
  margin-bottom: 5rem !important;
  margin-top: 1rem !important;
}

.project_typography1 {
  font-size: 35px !important;
  font-weight: 700 !important;
}
.project_body {
  /* margin-left: 7rem; */
  /* margin-right: 7rem; */
  font-family: "Poppins", sans-serif;
}

.projectObj_footer {
  margin-bottom: 4rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
}
