.autism_body {
  font-family: "Poppins, sans-serif";
}
.autism_Typography {
  font-weight: 600 !important;
  color: rgb(0, 0, 0);
}

.autism_Typography2 {
  font-style: italic !important;
  font-weight: 400 !important;
  color: #040404;
}

.autism_who {
  font-size: 22px !important;
  font-style: italic !important;
  font-weight: 500 !important;
  color: #49108b;
}
