.learn_h4 {
  font-weight: 800;
  font-size: 55px;
  text-align: center;
  margin-bottom: 5rem;
  margin-top: 1rem;
}

.learn_body {
  margin-left: 7rem;
  margin-right: 7rem;
}

.learn_typography1 {
  font-weight: 700 !important;
}

.learn_typography3 {
  font-weight: 650;
}
